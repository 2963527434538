const baseFitmentFields = ['Year', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/shop-by-brand';

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    searchResultsImageWidth: 190,
    searchResultsImageHeight: 200,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    vehicleDataExpires: 30,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-collection .block-section.products',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
      sortSelectClass: 'select__select caption-large',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body.template-index #MainContent',
        class: 'container cm_vehicle-widget__home-container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 850,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 600,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.template-product product-form' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'Garage',
      location: { insertBefore: '#mini-cart-toggle' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBox',
      location: { replace: 'predictive-search > form', class: 'cm_mobile-hide' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { insertBefore: '.head-slot-nav-mobile-link-wrapper', class: 'cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        replace: 'body.template-list-collections .collection-list',
      },
      template: 'facetTiles/hierarchicalContainer',
      columnBreakpoint: 600,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      columnBreakpoint: 600,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
